<template>
  <b-card class="product-edit-wrapper">
    <validation-observer ref="editSaleValidation">
      <b-form class="mt-2">
        <b-row>
          <!-- Title -->
          <b-col md="6">
            <b-form-group
              label="Title"
              label-for="sale-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  id="sale-edit-title"
                  v-model="saleEdit.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Type -->
          <b-col md="6">
            <b-form-group
              label="Type"
              label-for="sale-edit-type"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <v-select
                  id="sale-edit-type"
                  v-model="saleEdit.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="saleType"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Region -->
          <b-col md="6">
            <b-form-group
              label="Region"
              label-for="sale-edit-region"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Region"
                rules="required"
              >
                <v-select
                  id="sale-edit-region"
                  v-model="saleEdit.region"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regionOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  @input="enableMultipleSelect"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Select Date Range -->
          <b-col md="6">
            <b-form-group
              label="Select Date Range"
              label-for="sale-add-date-range"
              class="mb-2"
            >
              <date-range-picker
                ref="picker"
                v-model="saleEdit.dateRange"
                opens="opens - right"
                :auto-apply="true"
              >
                <template v-slot:input="picker">
                  {{ formatDate(picker.startDate) }} - {{ formatDate(picker.endDate) }}
                </template>
              </date-range-picker>
            </b-form-group>
          </b-col>

          <!-- Bulk Sale Config -->
          <b-col
            v-if="saleEdit.type === 'bulk'"
            md="12"
            class="mb-2"
          >
            <label for="sale-config">General config for sale campaign</label>
            <transition-group
              id="sale-config"
              name="custom-classes"
              enter-active-class="animate__animated animate__fadeInDown"
              leave-active-class="animate__animated animate__fadeOutUp"
              :duration="300"
            >
              <b-card-body
                v-for="index in countSaleBulk"
                :key="index"
              >
                <!-- repeater from -->
                <b-col cols="12">
                  <div class="border rounded p-2">
                    <div
                      v-if="saleEdit.bulkConfigs.length > 1"
                      class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                    >
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeItem(index - 1)"
                      />
                    </div>
                    <!-- Quantity -->
                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label="Quantity"
                          label-for="sale-quantity"
                          class="mb-2"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Quantity"
                            rules="required"
                          >
                            <b-input-group>
                              <b-form-input
                                id="sale-quantity"
                                v-model="saleEdit.bulkConfigs[index - 1].amount"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Discount -->
                      <b-col md="6">
                        <b-form-group
                          label="Discount"
                          label-for="quantity-discount"
                          class="mb-2"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Discount"
                            rules="required"
                          >
                            <b-input-group>
                              <b-form-input
                                id="quantity-discount"
                                v-model="saleEdit.bulkConfigs[index - 1].percent"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-card-body>
            </transition-group>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="ml-1"
                size="sm"
                variant="primary"
                @click="addNewItemInItemFormSaleBulk"
              >
                Add config
              </b-button>
            </b-col>
          </b-col>
          <!-- Batch Config -->
          <b-col
            v-if="saleEdit.type=== 'batch'"
            md="6"
          >
            <b-form-group
              label="Sale Percent"
              label-for="sale-percent"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Sale Percent"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="sale-percent"
                    v-model="saleEdit.batchDiscountPercent"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="saleEdit.type=== 'batch'"
            md="6"
          >
            <b-form-group
              label="Quantity"
              label-for="sale-quantity"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Quantity"
                rules=""
              >
                <b-input-group>
                  <b-form-input
                    id="sale-quantity"
                    v-model="saleEdit.quantity"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Coupon Config -->
          <b-col
            v-if="saleEdit.type === 'coupon'"
            md="6"
          >
            <b-form-group
              label="Sale Price"
              label-for="sale-price"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Sale Price"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="sale-price"
                    v-model="saleEdit.fixPriceDiscount"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Products -->
          <!-- <b-col md="12">
            <b-form-group
              label="Assets"
              label-for="sale-edit-products"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Assets"
                rules="required"
              >
                <multiselect
                  id="id"
                  v-model="saleEdit.products"
                  :disabled="disabledMultipleSelect"
                  label="title"
                  track-by="id"
                  placeholder="Type to search assets"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="fetchProducts"
                  @open="fetchProducts"
                >
                  <template
                    slot="tag"
                    slot-scope="{ option, remove }"
                  >
                    <span
                      class="tag custom__tag"
                      @click="remove(option)"
                    >{{ option.title }}</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- Products -->
          <b-col md="12">
            <b-form-group
              label="Assets"
              label-for="sale-add-products"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Assets"
                rules="required"
              >
                <v-select
                  :disabled="disabledMultipleSelect"
                  v-model="saleEdit.products"
                  multiple
                  label="title"
                  :options="products"
                  :filterable="false"
                  @open="fetchProducts"
                  @search="fetchProducts"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-if="!saleId"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="addSale"
            >
              Add Campaign
            </b-button>
            <b-button
              v-if="saleId"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="updateSale"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :to="{ name: 'sale-campaign' }"
              variant="outline-secondary"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormInput, BFormGroup,
  BCard, BRow, BCol, BForm, BButton, BCardBody, BInputGroup,
} from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import vSelect from 'vue-select'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import DateRangePicker from 'vue2-daterange-picker'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { ref } from '@vue/composition-api'
import router from '@/router'
import editSale from './detailSale'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { isExpired } from "@/utils/utils";

export default {
  components: {
    Multiselect,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    DateRangePicker,
    BCardBody,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters('app', ['regionOptions']),
    ...mapGetters('sale', ['saleType']),
  },
  data() {
    return {
      products: [],
      isLoading: false,
      search_query: '',
      commentCheckmark: '',
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
      required,
    }
  },
  setup() {
    const disabledMultipleSelect = ref(false)
    const {
      saleEdit,
      saleId,
      countSaleBulk,
    } = editSale()
    return {
      disabledMultipleSelect,
      saleEdit,
      saleId,
      countSaleBulk,
      isExpired,
    }
  },
  methods: {
    enableMultipleSelect() {
      if (this.saleEdit.region) {
        this.disabledMultipleSelect = false
      } else {
        this.disabledMultipleSelect = true
        this.saleEdit.products = []
      }
    },
    updateSale() {
      this.$refs.editSaleValidation.validate().then(success => {
        if (success) {
          const saleUpdateData = {
            name: this.saleEdit.title,
            region: this.saleEdit.region,
            type: this.saleEdit.type,
            batchDiscountPercent: this.saleEdit.type === 'batch' ? this.saleEdit.batchDiscountPercent : null,
            fixPriceDiscount: this.saleEdit.type === 'coupon' ? this.saleEdit.fixPriceDiscount : null,
            bulkConfigs: this.saleEdit.type === 'bulk' ? this.saleEdit.bulkConfigs.map(x => ({
              amount: x.amount,
              percent: x.percent,
            })) : null,
            quantity: this.saleEdit.quantity,
            isEndable: isExpired(this.saleEdit.dateRange.endDate) ? false : this.saleEdit.isEndable,
            productIds: this.saleEdit.products.map(x => x.id),
            startTime: this.saleEdit.dateRange.startDate,
            endTime: this.saleEdit.dateRange.endDate,
          }
          store.dispatch('sale/updateSale', { id: this.saleId, sale: saleUpdateData }).then(response => {
            if (response.request.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Update sale successfully',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              router.push('/sale-campaign')
            }
          }).catch(e => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${e.message}`,
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    formatDate(time) {
      return moment(time).format('DD/MM/YYYY')
    },
    fetchProducts(searchQuery) {
      this.isLoading = true
      store.dispatch('product/fetchProducts', {
        region_ids: this.saleEdit.region,
        search: searchQuery,
        limit: 10,
        page: 1,
      })
        .then(response => {
          this.products = response.body.data
          this.isLoading = false
        })
    },
    removeItem(index) {
      this.saleEdit.bulkConfigs.splice(index, 1)
      this.countSaleBulk -= 1
    },
    addNewItemInItemFormSaleBulk() {
      this.saleEdit.bulkConfigs.push({
        amount: '',
        percent: '',
      })
      this.countSaleBulk += 1
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vue-daterange-picker {
  width: 100%;
}
.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  span {
    float: left;
  }
}

.tag {
  background: #41b883;
  border-radius: 3px 0 0 3px;
  color: #fff;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #41b883;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: crimson;
  color: white;
}

.tag:hover::after {
   border-left-color: crimson;
}
</style>
