import { ref } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";

export default function editSale() {
  const saleId = ref(null)
  const saleEdit = ref({
    title: '',
    type: {},
    region: {},
    dateRange: {},
    products: [],
    bulkConfigs: [],
    batchDiscountPercent: 0,
    fixPriceDiscount: 0

  })
  const countSaleBulk = ref(0)

  const refetchData = () => {
    refSalesTable.value.refresh()
  }
  saleId.value = router.currentRoute.params.id
  setTimeout(() => {
    store
      .dispatch("sale/fetchSale", { id: saleId.value })
      .then((response) => {
        const data = response.body;
        saleEdit.value = {
          title: data.name,
          type: data.type,
          region: data.region,
          dateRange: {
            startDate: new Date(data.startTime),
            endDate: new Date(data.endTime),
          },
          bulkConfigs: data.bulkConfigs ?? [],
          batchDiscountPercent: data.batchDiscountPercent,
          fixPriceDiscount: data.fixPriceDiscount,
          products: data.products,
          quantity: data.quantity,
          isEndable: data.isEndable
        }
        countSaleBulk.value = data.bulkConfigs.length;
      })
      .catch((error) => {
      })
  }, 0)
  return {
    saleEdit,
    saleId,
    countSaleBulk,
    refetchData,
  };
}
